import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SkeletonLoader from '../../SkeletonLoader';
import theme from '../../../theme_v2';
import ConsentNews from '../../../components/consentNews';

const useStyles = makeStyles(() => ({
  height100: {
    minHeight: '100%',
  },
  container: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  bg1: {
    background: `url(${require('../../../assets/images/membership_dark_bg.svg')})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  bg2: {
    background: `url(${require('../../../assets/images/membership_dark_bg.svg')})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  bg3: {
    background: `url(${require('../../../assets/images/4_3_3.svg')})`,
    backgroundColor: '#1D99E5',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  bg4: {
    background: `url(${require('../../../assets/images/4_3_3.svg')})`,
    backgroundColor: '#1D99E5',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  bg5: {
    background: `url(${require('../../../assets/images/login_employee_bg.png')})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  'section-bg2': {
    backgroundColor: 'white',
    marginLeft: -16,
    marginRight: -16,
    padding: 16,
    borderRadius: '12px 12px 0 0'
  },
  'section-bg4': {
    backgroundColor: 'white',
    marginLeft: -16,
    marginRight: -16,
    padding: 16,
    borderRadius: '12px 12px 0 0'
  },
}));

const LayoutCard = ({ children, bg = 'bg1', title, description, loading }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{title} - MorOnline</title>
      </Helmet>
      <ConsentNews />
      {loading ? <SkeletonLoader/> : children}
      {/* <section className={`${classes['section-' + bg]} ${classes.height100}`}>
        {loading ? <SkeletonLoader/> : children}
      </section> */}
      {/* <Grid
        container
        spacing={0}
        direction="column"
        style={{ height: '100vh', width: '100vw' }}
      >
        <Grid item className={`${classes.container} ${classes[bg]} ${classes.height100}`}>
          <Container maxWidth="lg" className={classes.height100}>
            <Box pt={4} height="100%">
              <Box mb={3}>
                <Typography style={{color:'white'}} variant="subtitle1" paragraph>{description}</Typography>
              </Box>
              <section className={`${classes['section-' + bg]} ${classes.height100}`}>
                {loading ? <SkeletonLoader/> : children}
              </section>
            </Box>
          </Container>
        </Grid>
      </Grid> */}
    </ThemeProvider>
  );
}

export default LayoutCard;