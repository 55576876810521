import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../components/v2/Layout';
import { Box, Button, Card, CardContent, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Snackbar, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Table from '../../components/Table';
import { getRedeem } from '../../apis';
import { getUrlParams } from '../../helpers/location';
import LayoutCard from '../../components/v2/LayoutCard';
import Container from '@material-ui/core/Container';
import html2canvas from 'html2canvas';
import { navigateTo } from '../../components/CrossPlatformLink';

const content = {
  'picture_congrat': '',
  'picture_emori': '',
  'header-p1': 'สมาชิก',
  'congrat1': 'ยินดีด้วย',
  'congrat2': 'คุณได้รับของขวัญ!',
  // 'copied': 'กรุณา copy หรือแคปหน้าจอโค้ดนี้ไปใส่ที่ช่องโปรโมชั่นหน้าชำระเงิน',
  'copied': 'กรุณาคัดลอกรหัสนี้และนำไปกรอกที่หน้า redemtion เพื่อรับสิทธิ์',
  'name': 'Membership card',
};

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: 20,
    fontWeight: 800,
    fontSize: '1.05em',
    marginTop: '10px',
  },
  textCard: {
    margin: '8px 0px',
  },
  height100: {
    minHeight: '100%',
  },
  space: {
    padding: '0px',
    margin: '0px',
  },
  container: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  bg1: {
    background: `url(${require('../../assets/images/membership_dark_bg.svg')})`,
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}));

export default function ResultGift({ location }) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState('');
  const params = getUrlParams(location.search);
  const { id } = params;
  const redeemCardRef = useRef(null);
  const { source } = useSelector(state => state.session);

  React.useEffect(() => {
    redeem();
  }, []);

  const redeem = async () => {
    try {
      const res = await getRedeem(id);
      setCode(res.redeem_code);
    } catch (e) {}
  }

  const screenShot = (element) => {
    html2canvas(element).then((canvas) => {
      const image = canvas.toDataURL('png');
      const a = document.createElement('a');
      a.setAttribute('download', 'redeemCard.png');
      a.setAttribute('href', image);
      a.click();
    });
  }

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(code);
  };

  return (
    <LayoutCard title={content['header-p1']}>
      <Grid
        container
        spacing={0}
        direction="column"
        style={{ height: '100vh', width: '100vw', position: 'relative' }}
      >
        <Grid item className={`${classes.container} ${classes['bg1']} ${classes.height100}`}>
          <Container maxWidth="lg" className={`${classes.height100} ${classes.space}`}>
            <Box pt={4} height="100%">
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <div style={{ background: 'linear-gradient(#FFF4D4, #FFDCE9)', zIndex: 2, position: 'absolute', margin: '25px', borderRadius: '15px', padding: '15px' }}>
                    <div ref={redeemCardRef} style={{ background: 'linear-gradient(#FFF4D4, #FFDCE9)', borderRadius: '15px', marginTop: '15px' }}>
                      <Box textAlign='center'>
                        <img src={require('../../assets/images/noto_confetti-ball.svg')} />
                        <Typography variant="h6" component="h1">{content['congrat1']}</Typography>
                        <Typography variant="h5" component="h2">{content['congrat2']}</Typography>
                        <img src={require('../../assets/images/shy.svg')} />
                        <Box my={1} style={{ padding: '5px', margin: '15px' }}>
                          <Grid container spacing={1} style={{ display: 'flex', borderRadius: '10px', justifyContent: 'center', alignItems: 'center', height: '50px', alignContent: 'center', backgroundColor: 'white'}}>
                            <Grid item>
                              <p>{code}</p>
                            </Grid>
                            <Grid item>
                              <FileCopyIcon onClick={() => handleClick()}/>
                            </Grid>
                          </Grid>
                          <Snackbar // for copied code
                            message='Copied to clibboard'
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            autoHideDuration={2000}
                            onClose={() => setOpen(false)}
                            open={open}
                          />
                        </Box>
                        <Typography variant='subtitle1' component='p' style={{ margin: '0px 40px' }}>{content['copied']}</Typography>
                        <Typography variant='h5' component='h2' className={classes.textCard}>{content['name']}</Typography>
                      </Box>
                    </div>
                    <Box textAlign='center'>
                      {/* <Button
                        fullWidth
                        size='large'
                        variant='contained'
                        color='primary'
                        className={classes.btn}
                        onClick={() => screenShot(redeemCardRef.current)}
                      >
                        บันทึกรูปภาพ
                      </Button> */}
                      {/* <Box mt={1}>
                        <Button
                          fullWidth
                          variant='text'
                          size='large'
                          onClick={() => navigateTo(source, 'me.moronline://exit')}
                        >
                          ปิดหน้านี้
                        </Button>
                      </Box> */}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </LayoutCard>
  );
}